import { Link } from "gatsby";
import React from "react";


function DesktopGrid({ imageObjects, preview }) {

  function calculateCssGrid(imageObjects, preview) {
    let arrayLength;
    if (preview) arrayLength = (imageObjects.length < 3) ? (imageObjects.length) : 3
   else arrayLength = imageObjects.length
    
    let cssGrid;
    switch (arrayLength) {
      case 1:
        cssGrid = 'lg:h-80'
        break;
      case 2:
        cssGrid = 'lg:grid-cols-2 lg:grid-rows-1 lg:h-80'
        break;
      case 3:
        cssGrid = 'lg:grid-cols-3 lg:grid-rows-1 lg:h-80'
        break;
      default:
        cssGrid = 'lg:grid-cols-3 lg:grid-rows-2 lg:h-10/12'
        break;
    }
    return cssGrid
  }

  // console.log(imageObjects)
  // console.log(imageObjects.length)
  const cssGrid = calculateCssGrid(imageObjects, preview)

  return (
    <div className={`hidden lg:grid overflow-hidden relative w-full ${cssGrid} gap-4 px-4`}>
      {
        imageObjects.map((svc, index) => {
          if (preview) {
            if (index < 3) {
              return (
                <Link key={index} className="overflow-hidden group relative col-span-1 row-span-1 block w-full h-full aspect-square rounded-2xl hover:shadow-xl" to={svc.link}>
                  {svc.img}
                  <div className="absolute top-0 left-0 w-full h-full bg-neutral-800 mix-blend-multiply bg-opacity-60 group-hover:bg-red-800 group-hover:bg-opacity-70 transition-all duration-500 ease-in"></div>
                  <div className="px-6 md:px-4 absolute inset-0 object-center flex flex-col justify-center items-center text-center gap-6 md:gap-8 text-white text-opacity-75 group-hover:text-opacity-100 transition-all duration-500 ease-in">
                    <h1 className="font-bold uppercase select-none">{svc.name}</h1>
                    <p className="font-normal text-sm uppercase select-none">{svc.description}</p>
                  </div>
                </Link>
              )
            }
          } else {
            return (
              <Link key={index} className="overflow-hidden group relative col-span-1 row-span-1 block w-full h-full aspect-square rounded-2xl hover:shadow-xl" to={svc.link}>
                {svc.img}
                <div className="absolute top-0 left-0 w-full h-full bg-neutral-800 mix-blend-multiply bg-opacity-60 group-hover:bg-red-800 group-hover:bg-opacity-70 transition-all duration-500 ease-in"></div>
                <div className="px-6 md:px-4 absolute inset-0 object-center flex flex-col justify-center items-center text-center gap-6 md:gap-8 text-white text-opacity-75 group-hover:text-opacity-100 transition-all duration-500 ease-in">
                  <h1 className="font-bold uppercase">{svc.name}</h1>
                  <p className="font-normal text-sm uppercase">{svc.description}</p>
                </div>
              </Link>
            )
          }
          return null
        })
      }
    </div>
  )
}

export default DesktopGrid