import React from "react";
import DesktopGrid from './DesktopGrid';
import MobileGrid from './MobileGrid';


function ImageGrid({ title, subtitle, btn, imageObjects, preview }) {

  const [manualMoved, setManualMoved] = React.useState(false)
  const [index, setIndex] = React.useState(0)

  const MobileGridBtns = () => (
    <div className="lg:hidden flex gap-4 justify-center">
      <div
        role='button'
        tabIndex={0}
        className="flex justify-center items-center cursor-pointer group w-10 h-10 rounded-full nexus-svg border-transparent bg-neutral-800 hover:bg-red-800/75 dark:bg-neutral-900/75 hover:bg-red-900 duration-300"
        onClick={() => decrementIndex()}
        onKeyUp={() => null}
        onKeyDown={(e) => { e.preventDefault(); if (e.key === 'Enter') decrementIndex() }}
      >
        <svg className="w-10 h-10 p-3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
          <path className="fill-neutral-100" d="M41.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l192 192c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.3 256 278.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-192 192z" />
        </svg>
      </div>
      <div
        role='button'
        tabIndex={0}
        className="flex justify-center items-center cursor-pointer group w-10 h-10 rounded-full nexus-svg border-transparent bg-neutral-800 hover:bg-red-800/75 dark:bg-neutral-900/75 hover:bg-red-900 duration-300"
        onClick={() => incrementIndex()}
        onKeyUp={() => null}
        onKeyDown={(e) => { e.preventDefault(); if (e.key === 'Enter') incrementIndex() }}
      >
        <svg className="w-10 h-10 p-3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
          <path className="fill-neutral-100" d="M342.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L274.7 256 105.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z" />
        </svg>
      </div>
    </div>
  )

  function decrementIndex() {
    setManualMoved(true)
    setIndex(cur => {
      if ((cur - 1) < 0) return imageObjects.length - 1;
      else return cur - 1
    })
    return null
  }

  function incrementIndex() {
    setManualMoved(true)
    setIndex(cur => {
      if ((cur + 1) >= imageObjects.length) return 0;
      else return cur + 1
    })
    return null
  }

  return (
    <div className="relative w-full v-page-p nexus-bg flex flex-col gap-6">
      <div className="w-full h-page-p flex flex-col gap-6">
        <h1 className="text-center font-bold text-2xl nexus-text uppercase select-none">{title}</h1>
        <p className="text-base text-center nexus-text select-none">{subtitle}</p>
      </div>

      {
        imageObjects && (
          <>
            {imageObjects.length > 1 && <MobileGridBtns />}
            <MobileGrid
              imageObjects={imageObjects}
              index={index}
              manualMoved={manualMoved}
            />

            <DesktopGrid
              imageObjects={imageObjects}
              preview={preview}
            />
          </>
        )
      }
      {
        btn &&
        <div className="relative w-full nexus-bg h-page-p">
          <div className="flex flex-col items-center text-center">
            {btn}
          </div>
        </div>
      }

    </div>
  )
}

export default ImageGrid