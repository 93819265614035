import React from "react"
import ContactForm from "../lib/components/ContactForm"

import Hero from '../lib/components/Hero'
import ImageGrid from '../lib/components/ImageGrid'
import Intro from "../lib/components/Intro"
import Layout from "../lib/components/Layout"
import NexusButton from "../lib/components/NexusButton"
import TwoColumnContent from "../lib/components/TwoColumnContent"
import ValueProp from '../lib/components/ValueProp'

import Storage from '../lib/storage'

function Page({ location }) {

  // React hook for checking if screen is mobile size
  const [width, setWidth] = React.useState(null);
  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  React.useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener('resize', handleWindowSizeChange);
      return () => {
        window.removeEventListener('resize', handleWindowSizeChange);
      }
    }
    if (typeof window !== "undefined") {
      setWidth(window.innerWidth)
    }

  }, []);
  const isMobile = width <= 768;

  return (
    <Layout
      title={Storage.pages.home.metadata.title}
      description={Storage.pages.home.metadata.description}
      pageUrl={location.pathname}
      bgNavSelector='hero'
    >
      <>
        <Hero
          backgroundImg={Storage.pages.home.hero.img}
          link={Storage.pages.home.hero.link}
          isMobile={isMobile}
        />

        <Intro
          title='Let Nexus Commercial Services protect you from any water damages as part of your next building construction and maintenance in Melbourne.'
          subtitle={
            <div className="flex flex-col gap-6">
              <h2 className="text-base nexus-text">We source modern and high-quality waterproofing materials to ensure effective and long-lasting results for your next home or commercial construction. Our services are flexible in providing you with the assistance you need to effectively waterproof your buildings.</h2>
              <h2 className="font-bold text-sm nexus-text">With our help, you can have peace of mind knowing that your building is protected from the damaging effects of water.</h2>
            </div>
          }
          btn={
            <NexusButton
              title='Purchase Now'
              link='https://shop.nexuscommercial.com.au'
              linkType='external'
            />
          }
          ctaId='homeCta'
          ctaText='...Or are you interested in engaging with us?'
        />

        {/* <ImageGrid
          title={Storage.pages.productGroups.metadata.title}
          subtitle={(
            <span className="grid gap-4">
              <span className="nexus-text">{Storage.pages.productGroups.metadata.description}</span>
              <span className="nexus-text text-sm font-bold">{isMobile ? 'Swipe on your phone or click the buttons on your desktop' : 'Click on each panel'} to look through our available products.</span>
            </span>
          )}
          btn={<NexusButton linkType='external' title='Purchase our Products' link='https://shop.nexuscommercial.com.au' />}
          imageObjects={Storage.global.productGroups}
        /> */}

        <ImageGrid
          title={Storage.pages.services.metadata.title}
          subtitle={(
            <span className="grid gap-4">
              <span className="nexus-text select-none">{Storage.pages.services.metadata.description}</span>
              <span className="nexus-text text-sm font-bold select-none">{isMobile ? 'Swipe on your phone or click the buttons on your desktop' : 'Click on each panel'} to look through our available services.</span>
            </span>
          )}
          btn={<NexusButton linkType='internal' title='View All Services' link='/services' />}
          imageObjects={Storage.global.services}
          preview
        />

        <ValueProp
          tagline={Storage.global.valueProp.tagline}
          title={Storage.global.valueProp.title}
          subtitle={Storage.global.valueProp.subtitle}
          valuePropList={Storage.global.valueProp.valuePropList}
          isMobile={isMobile}
        />

        <TwoColumnContent
          title='Your One-Stop shop for Waterproofing Repairs and Products in Melbourne'
          subtitle='Protect your buildings with trusted waterproofing services. We provide quality waterproofing products across Melbourne to assist with maintaining structural integrity of all new development and buildings against water-related damage. At Nexus Commercial Services, we strive to provide the highest quality services and products in order to protect your buildings from leaks and costly damages by offering a wide selection of both in order to cater to your needs. All the products that we sell are CNAS approved, a distinguished achievement that recognises the high quality of our waterproofing products.'
          tagline='Why Choose Us'
          btn={<NexusButton title='Learn More About Nexus Commercial Services' link='/about-us' theme='accent' />}
          isMobile={isMobile}
        />

        {/* <div> */}
        {/* TODO: Testimonial / Review section */}
        {/* </div> */}

        {/* <Cta
          backgroundImg={Storage.pages.home.cta.img}
          title={Storage.pages.home.cta.title}
          btn={<NexusButton title='Get in Touch With Us' link='/contact-us' theme='dark' />}
        /> */}

        <div id='homeCta' className="relative w-full h-page-p v-page-p nexus-bg">
          <div className="flex flex-col gap-4">
            <div className="grid gap-4">
              <h1 className="font-bold uppercase nexus-text">Contact Us</h1>
              <p className="font-normal nexus-text">We will be able to provide a more detailed response on your product or service enquiry.</p>
            </div>
            <ContactForm />
          </div>
        </div>
      </>
    </Layout>
  )
}

export default Page
