import { motion, useAnimation, useReducedMotion } from "framer-motion"
import React from "react"
import { useInView } from "react-intersection-observer"

import ContactForm from '../components/ContactForm'

const fadeUp = {
  initial: { y: '20px', opacity: 0 },
  animate: { y: 0, opacity: 1, transition: { duration: 0.5 } },
  reducedMotion: { opacity: 1, transition: { duration: 0, delay: 0 } }
}

const fadeDown = {
  initial: { y: '-20px', opacity: 0 },
  animate: { y: 0, opacity: 1, transition: { duration: 0.5, delay: 0.5 } },
  reducedMotion: { opacity: 1, transition: { duration: 0, delay: 0 } }
}

function Hero({ backgroundImg, link, isMobile }) {

  let intro
  if (typeof document !== 'undefined') intro = document.getElementById(link)

  const prefersReducedMotion = useReducedMotion()
  const control = useAnimation()
  const [ref, inView] = useInView()

  React.useEffect(() => {
    if (isMobile || prefersReducedMotion) control.start("reducedMotion")
    else if (inView) control.start("animate");
    else if (!inView) control.start("initial");
  }, [control, inView, isMobile, prefersReducedMotion]);

  return (
    <motion.div
      id="hero"
      className="relative w-full h-screen min-h-full overflow-hidden"
      ref={ref}
    >
      {backgroundImg}
      {/* BG Overlay */}
      <div className="absolute inset-0 w-full h-full nexus-neutral-bg bg-opacity-70 mix-blend-multiply"></div>
      {/* Text Container */}
      <div className="flex justify-start lg:justify-between items-center w-full h-full absolute inset-0 h-page-p overflow-hidden gap-12 leading-relaxed tracking-wide">

        <div className="flex flex-col gap-20">
          <motion.div
            className="text-left uppercase flex flex-col justify-center items-start gap-10 2xl:gap-20"
            variants={fadeUp}
            initial="initial"
            animate={control}
          >
            <h1 className="bg-opacity-30 !font-normal nexus-light-text text-3xl md:text-5xl 2xl:text-6xl leading-relaxed tracking-wide select-none">Repair</h1>
            <h1 className="bg-opacity-30 !font-normal nexus-light-text text-3xl md:text-5xl 2xl:text-6xl leading-relaxed tracking-wide select-none">Waterproof</h1>
            <h1 className="bg-opacity-30 !font-normal nexus-light-text text-3xl md:text-5xl 2xl:text-6xl leading-relaxed tracking-wide select-none">and Protect</h1>
            <h1 className="bg-opacity-30 !font-normal nexus-light-text text-3xl md:text-5xl 2xl:text-6xl leading-relaxed tracking-wide select-none">your buildings</h1>
          </motion.div>

          {
            // Arrow
            <motion.div
              className="block animate-bounce cursor-pointer w-12 h-12 sm:block md:w-16 md:h-16 group lg:hidden"
              variants={fadeDown}
              initial="initial"
              animate={control}
              onClick={() => intro.scrollIntoView({ behavior: 'smooth' })}
              onKeyUp={() => null}
              onKeyDown={(e) => { if (e.key === 'Enter') intro.scrollIntoView({ behavior: 'smooth' }) }}
            >
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1" stroke="currentColor" className="w-14 h-14 text-white opacity-80 group-hover:opacity-100">
                <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75l3 3m0 0l3-3m-3 3v-7.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
              </svg>
            </motion.div>
          }
        </div>

        <div className="hidden lg:block" />
        {
          // Contact Form
          <div className="hidden group w-full max-w-md lg:flex lg:flex-col justify-center items-center nexus-neutral-bg bg-opacity-40 p-4 gap-4 backdrop-blur-lg">
            <h1 className="text-center font-normal uppercase nexus-light-text tracking-wide select-none">Enquire For a Quote Now</h1>
            <h2 className="text-center font-normal uppercase nexus-light-text tracking-tight text-sm select-none">We offer Free Quote for Repairs over $80.</h2>
            <div className="w-full h-full">
              <ContactForm theme='inverted' />
            </div>
          </div>
        }

      </div>
    </motion.div>
  )
}

export default Hero