import { Link } from "gatsby";
import React from "react";


function MobileGrid({ imageObjects, index, manualMoved }) {

  React.useEffect(() => {
    if (typeof document !== 'undefined') {
      if (manualMoved) document.getElementById(imageObjects[index].link).scrollIntoView({ behavior: 'smooth', block: 'center' })
      return
    }
  }, [imageObjects, index, manualMoved])

  return (
    <div className="lg:hidden relative w-full h-full">
      <div className="flex lg:hidden snap-x w-full h-80 mx-auto snap-mandatory overflow-scroll scrollbar-hide gap-4 px-4">
        {
          imageObjects.map((svc) => {
            return (
              <Link key={svc.link} id={`${svc.link}`} to={svc.link} className={`relative snap-center flex flex-shrink-0 overflow-hidden w-full h-full group aspect-square rounded-2xl hover:shadow-xl`}>
                {svc.img}
                <div className="absolute top-0 left-0 w-full h-full bg-neutral-800 mix-blend-multiply bg-opacity-60 rounded-2xl group-hover:bg-red-800 group-hover:bg-opacity-70 transition-all duration-500 ease-in" />
                <div className="px-12 absolute inset-0 object-center flex flex-col justify-center items-center text-center gap-6 md:gap-8 text-white text-opacity-75 group-hover:text-opacity-100 transition-all duration-500 ease-in">
                  <h1 className="font-bold uppercase select-none">{svc.name}</h1>
                  <p className="font-normal text-sm uppercase select-none">{svc.description}</p>
                </div>
              </Link>
            )
          })
        }
      </div>
    </div >
  )
}

export default MobileGrid